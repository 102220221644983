// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavLink_navLink__Gls\\+K {
  color: #000;
  font-size: 20px;
  line-height: 23.54px;
  text-decoration: none;
}
.NavLink_navLink__Gls\\+K:hover {
  color: #60D7BB;
  border-bottom: 1px solid #60D7BB;
}

.NavLink_active__VWpmO {
  color: #42B99D;
}`, "",{"version":3,"sources":["webpack://./src/shared/modules/NavLink/NavLink.module.scss","webpack://./src/shared/styles/colors.scss"],"names":[],"mappings":"AAGA;EACI,WCcS;EDbT,eAAA;EACA,oBAAA;EACA,qBAAA;AAFJ;AAII;EACI,cCSW;EDRX,gCAAA;AAFR;;AAMA;EACI,cCIgB;ADPpB","sourcesContent":["\r\n@use \"../../styles/colors.scss\" as *;\r\n\r\n.navLink{\r\n    color: $color_link;\r\n    font-size: 20px;\r\n    line-height: 23.54px;\r\n    text-decoration: none;\r\n\r\n    &:hover{\r\n        color: $color_link_hover;\r\n        border-bottom: 1px solid $color_link_hover;\r\n    }\r\n}\r\n\r\n.active{\r\n    color: $color_link_active;\r\n}","\r\n$color_logo: #60D7BB;\r\n\r\n//* SPOT\r\n$spot_color: #60D7BB;\r\n\r\n\r\n//* INPUTS\r\n$input_placeholder_color: #ADB5BD;\r\n$inputForm_color_border: #60D7BB;\r\n\r\n//* BUTTONS\r\n$btnGreen_background_color: #60D7BB;\r\n$btnGreen_color_hover: #42B99D;\r\n\r\n$btnGreenReverse_color_border: #60D7BB;\r\n\r\n//* LINKS\r\n$color_link: #000;\r\n$color_link_hover: #60D7BB;\r\n$color_link_active: #42B99D;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navLink": `NavLink_navLink__Gls+K`,
	"active": `NavLink_active__VWpmO`
};
export default ___CSS_LOADER_EXPORT___;
