// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputGreen_inputGreen__1F854 {
  border: 2px solid #60D7BB;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 400px;
  padding: 12px 30px;
  width: 100%;
}
.InputGreen_inputGreen__1F854::placeholder {
  color: #ADB5BD;
}`, "",{"version":3,"sources":["webpack://./src/shared/modules/InputGreen/InputGreen.module.scss","webpack://./src/shared/styles/colors.scss"],"names":[],"mappings":"AAGA;EACI,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AAFJ;AAII;EACI,cCJkB;ADE1B","sourcesContent":["\r\n@use \"../../styles/colors.scss\" as *;\r\n\r\n.inputGreen {\r\n    border: 2px solid $inputForm_color_border;\r\n    border-radius: 6px;\r\n    font-size: 20px;\r\n    font-weight: 400px;\r\n    padding: 12px 30px;\r\n    width: 100%;\r\n\r\n    &::placeholder{\r\n        color: $input_placeholder_color;\r\n    }\r\n}\r\n","\r\n$color_logo: #60D7BB;\r\n\r\n//* SPOT\r\n$spot_color: #60D7BB;\r\n\r\n\r\n//* INPUTS\r\n$input_placeholder_color: #ADB5BD;\r\n$inputForm_color_border: #60D7BB;\r\n\r\n//* BUTTONS\r\n$btnGreen_background_color: #60D7BB;\r\n$btnGreen_color_hover: #42B99D;\r\n\r\n$btnGreenReverse_color_border: #60D7BB;\r\n\r\n//* LINKS\r\n$color_link: #000;\r\n$color_link_hover: #60D7BB;\r\n$color_link_active: #42B99D;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputGreen": `InputGreen_inputGreen__1F854`
};
export default ___CSS_LOADER_EXPORT___;
