// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BtnGreen_btnGreen__XEJ2o {
  background-color: #60D7BB;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  color: #fff;
}
.BtnGreen_btnGreen__XEJ2o:hover {
  background-color: #42B99D;
}`, "",{"version":3,"sources":["webpack://./src/shared/modules/BtnGreen/BtnGreen.module.scss","webpack://./src/shared/styles/colors.scss"],"names":[],"mappings":"AAGA;EACI,yBCQwB;EDPxB,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;AAFJ;AAII;EACI,yBCDe;ADDvB","sourcesContent":["\r\n@use \"../../styles/colors.scss\" as *;\r\n\r\n.btnGreen{\r\n    background-color: $btnGreen_background_color;\r\n    border-radius: 30px;\r\n    padding: 10px 30px;\r\n    font-size: 20px;\r\n    font-weight: 600;\r\n    text-align: center;\r\n    cursor: pointer;\r\n    color: #fff;\r\n\r\n    &:hover{\r\n        background-color: $btnGreen_color_hover;\r\n    }\r\n}","\r\n$color_logo: #60D7BB;\r\n\r\n//* SPOT\r\n$spot_color: #60D7BB;\r\n\r\n\r\n//* INPUTS\r\n$input_placeholder_color: #ADB5BD;\r\n$inputForm_color_border: #60D7BB;\r\n\r\n//* BUTTONS\r\n$btnGreen_background_color: #60D7BB;\r\n$btnGreen_color_hover: #42B99D;\r\n\r\n$btnGreenReverse_color_border: #60D7BB;\r\n\r\n//* LINKS\r\n$color_link: #000;\r\n$color_link_hover: #60D7BB;\r\n$color_link_active: #42B99D;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnGreen": `BtnGreen_btnGreen__XEJ2o`
};
export default ___CSS_LOADER_EXPORT___;
