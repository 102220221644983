// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormRegistration_formRegistration__uXaKh {
  background-color: #fff;
  max-width: 460px;
  height: 348px;
  border-radius: 30px;
  padding: 40px 50px;
}
.FormRegistration_formRegistration__uXaKh > *:not(:last-child) {
  margin-bottom: 31px;
}
.FormRegistration_formRegistration__uXaKh .FormRegistration_formRegistration__title__w3V4C {
  font-size: 24px;
  text-align: center;
  font-weight: 400;
}
.FormRegistration_formRegistration__uXaKh .FormRegistration_formRegistration__inputs__hczSE > *:not(:last-child) {
  margin-bottom: 12px;
}
.FormRegistration_formRegistration__uXaKh .FormRegistration_formRegistration__btn__9N1dK {
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/widgets/FormRegistration/FormRegistration.module.scss"],"names":[],"mappings":"AACA;EACI,sBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,mBAAA;AAAR;AAGI;EACI,eAAA;EACA,kBAAA;EACA,gBAAA;AADR;AAKQ;EACI,mBAAA;AAHZ;AAOI;EACI,aAAA;EACA,uBAAA;AALR","sourcesContent":["\r\n.formRegistration{\r\n    background-color: #fff;\r\n    max-width: 460px;\r\n    height: 348px;\r\n    border-radius: 30px;\r\n    padding: 40px 50px;\r\n\r\n    > *:not(:last-child){\r\n        margin-bottom: 31px;\r\n    }\r\n\r\n    .formRegistration__title{\r\n        font-size: 24px;\r\n        text-align: center;\r\n        font-weight: 400;\r\n    }    \r\n\r\n    .formRegistration__inputs{\r\n        > *:not(:last-child){\r\n            margin-bottom: 12px;\r\n        }\r\n    }\r\n\r\n    .formRegistration__btn{\r\n        display: flex;\r\n        justify-content: center;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRegistration": `FormRegistration_formRegistration__uXaKh`,
	"formRegistration__title": `FormRegistration_formRegistration__title__w3V4C`,
	"formRegistration__inputs": `FormRegistration_formRegistration__inputs__hczSE`,
	"formRegistration__btn": `FormRegistration_formRegistration__btn__9N1dK`
};
export default ___CSS_LOADER_EXPORT___;
