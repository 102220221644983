// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__TlG7I {
  display: flex;
  justify-content: space-between;
  margin: 15px 90px;
  align-items: center;
}
.Header_header__TlG7I .Header_header__logo__0-2ad {
  font-size: 24px;
  line-height: 28.25px;
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: default;
}
.Header_header__TlG7I .Header_header__logo__0-2ad .Header_headerLogo__img__piJDJ {
  background-color: #60D7BB;
  width: 44px;
  height: 44px;
  border-radius: 50%;
}
.Header_header__TlG7I .Header_header__nav__nWxl\\+ {
  display: flex;
  gap: 40px;
}
.Header_header__TlG7I .Header_header__login__4SSmt {
  display: flex;
  align-items: center;
  gap: 40px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/widgets/Header/Header.module.scss","webpack://./src/shared/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,mBAAA;AADJ;AAGI;EACI,eAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AADR;AAGQ;EACI,yBChBC;EDiBD,WAAA;EACA,YAAA;EACA,kBAAA;AADZ;AAKI;EACI,aAAA;EACA,SAAA;AAHR;AAMI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;AAJR","sourcesContent":["@use \"../../shared/styles/colors.scss\" as *;\r\n\r\n.header{\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin: 15px 90px;\r\n    align-items: center;\r\n\r\n    .header__logo{\r\n        font-size: 24px;\r\n        line-height: 28.25px;\r\n        display: flex;\r\n        align-items: center;\r\n        gap: 14px;\r\n        cursor: default;\r\n\r\n        .headerLogo__img{\r\n            background-color: $color_logo;\r\n            width: 44px;\r\n            height: 44px;\r\n            border-radius: 50%;\r\n        }\r\n    }\r\n\r\n    .header__nav{\r\n        display: flex;\r\n        gap: 40px;\r\n    }\r\n\r\n    .header__login{\r\n        display: flex;\r\n        align-items: center;\r\n        gap: 40px;\r\n        cursor: pointer;\r\n    }\r\n\r\n}\r\n\r\n\r\n\r\n","\r\n$color_logo: #60D7BB;\r\n\r\n//* SPOT\r\n$spot_color: #60D7BB;\r\n\r\n\r\n//* INPUTS\r\n$input_placeholder_color: #ADB5BD;\r\n$inputForm_color_border: #60D7BB;\r\n\r\n//* BUTTONS\r\n$btnGreen_background_color: #60D7BB;\r\n$btnGreen_color_hover: #42B99D;\r\n\r\n$btnGreenReverse_color_border: #60D7BB;\r\n\r\n//* LINKS\r\n$color_link: #000;\r\n$color_link_hover: #60D7BB;\r\n$color_link_active: #42B99D;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__TlG7I`,
	"header__logo": `Header_header__logo__0-2ad`,
	"headerLogo__img": `Header_headerLogo__img__piJDJ`,
	"header__nav": `Header_header__nav__nWxl+`,
	"header__login": `Header_header__login__4SSmt`
};
export default ___CSS_LOADER_EXPORT___;
